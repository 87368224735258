<template>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text">Cambio de comercial</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="pa-4">
      <div class="d-flex align-center">
        <span class="text-h6 mr-4">Estudio: {{ estudio }}</span>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details="auto"
          solo
          v-model="search"
        />
      </div>

      <v-list dense>
        <v-list-item-group v-model="nuevo">
          <v-divider />
          <template v-for="(comercial, index) in comercialesFiltrados">
            <v-list-item :key="comercial.idUsuario" :value="comercial">
              <v-list-item-title>{{ comercial.nombre }}</v-list-item-title>
              <v-list-item-subtitle>{{ comercial.responsable?.nombre }}</v-list-item-subtitle>
            </v-list-item>
            <v-divider
              :key="`${index}-divider`"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>

      <template v-if="nuevo">
        <v-divider></v-divider>
        <div class="d-flex align-center">
          <v-card-title> Cambiar comercial a : </v-card-title>
          <b>{{ nuevo.nombre }}</b>
          <v-spacer></v-spacer>
          <v-btn @click.stop="cambiarComercial" color="primary" outlined
            >Aceptar</v-btn
          >
        </div>
      </template>
    </div>
    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
export default {
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  props: {
    estudio: String | Number,
    interno: Boolean,
  },
  data() {
    return {
      nuevo: null,
      comerciales: [],
      search: "",
    };
  },
  computed: {
    comercialesFiltrados() {
      if (!this.search) return this.comerciales;
      return this.comerciales.filter((c) =>
        c.nombre.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  async mounted() {
    try {
      const { data } = await axios(
        `${process.env.VUE_APP_API_URL}/colaboradores`
      );
      this.comerciales = data;
    } catch (e) {
      console.log(e);
      this.$root.$emit("snack", "Ha ocurrido un error");
    }
  },
  methods: {
    async cambiarComercial() {
      if (
        !(await this.$refs.confirm.open(
          "Cambiar tipo",
          "¿Estás seguro de que quieres asignar estos estudios a " +
            this.nuevo.nombre +
            "?"
        ))
      )
        return;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/estudios/cambiar_comercial`,
        data: {
          idEstudio: this.estudio,
          idUsuario: this.nuevo.idUsuario,
        },
      })
        .then((res) => {
          this.$root.$emit("snack", "Se ha cambiado el comercial con éxito");
          this.nuevo = null;
          this.$emit("close");
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido cambiar el comercial");
        });
    },
  },
};
</script>

<style></style>
